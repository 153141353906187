<template>
    <section class="panel-m" id="live-sessions">
    <div class="inner">
      <div class="section-header section-header--center">
        <h2 class="section-header__title" data-aos="fade-up">{{ block.fields['session_table__title'][0].value }}</h2>
        <div data-aos="fade-up" v-html="block.fields['session_table__intro'][0].value" />
      </div>
      <div class="narrow">
        <div class="table-blocks grid-x grid-margin-x small-up-2 large-up-5" data-aos="fade-up">

              <div v-for="(dayValue, dayKey) in theWeek" :key="dayKey" class="table-blocks__col cell">
                <h3 class="table-blocks__head h5">{{ dayValue }}</h3>
                <ul class="table-blocks__group no-bullet">
                    <template v-if="block.fields['session_table__' + dayKey + '_title'][0].value">
                          <li v-for="(title, subKey) in block.fields['session_table__' + dayKey + '_title']" :key="'sub_'+subKey" class="table-blocks__item">
                            <a target="_blank" class="table-blocks__link" :href="block.fields['session_table__' + dayKey + '_cta_url'][subKey].value">
                              <h4 class="table-blocks__time">{{ block.fields['session_table__' + dayKey + '_time'][subKey].value }}</h4>
                              <h5 class="table-blocks__title">{{ block.fields['session_table__' + dayKey + '_title'][subKey].value }}</h5>
                              <span>{{ block.fields['session_table__' + dayKey + '_cta_text'][subKey].value }}</span>
                            </a>
                          </li>
                    </template>
                    <li v-else class="table-blocks__item">
                        <p class="table-blocks__title">No sessions</p>
                    </li>
                </ul>
              </div>

        </div>
      </div>
    </div>
  </section>


</template>

<script>

    export default {
        name: "TwoColumnContent",
        props: {
            block: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                theWeek : {
                    'monday': 'Monday',
                    'tuesday': 'Tuesday',
                    'wednesday': 'Wednesday',
                    'thursday': 'Thursday',
                    'friday': 'Friday'
                }
            }
        },
        methods: {
            spacerValue() {
                var spacer = this.block.fields['two-col__spacer'][0].value || '';
                if(spacer === 'xsmall') return 'mb-18'
                if(spacer === 'small') return 'mb-32'
                if(spacer === 'medium') return 'mb-54'
                if(spacer === 'large') return 'mb-80'
                if(spacer === 'xlarge') return 'mb-120'
            }
        }
    }
</script>
